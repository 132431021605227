import React, { useEffect } from "react";
import beach from "../../images/northshore.jpg";
import logo from "../../images/tsunami-nav.png";
import { Link, NavLink } from "react-router-dom";
import Gallery from "../../components/Carousel/Carousel";
import ReviewWidget from "../../components/ReviewWidget/ReviewWidget";
import endImg from "../../images/sidewalk.jpg";
import sideBySide from "../../images/endSectionImg.JPG";
import PhoneIcon from "@mui/icons-material/Phone";
import Shower from '@mui/icons-material/Shower';
import WaterDamage from '@mui/icons-material/WaterDamage';
import Roofing from '@mui/icons-material/Roofing';
import { Icon } from "@mui/material";

import "./home.css";



const Home = () => {

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <header className="home__header" style={{backgroundImage: `url(${beach})`}}>
                <div className="header__container">
                    <div className="header fade-in">
                        <img src={logo} className="header__logo" alt="Tsunami Washing Service Logo"/>
                        <h3>Contact us today to schedule a free quote.</h3>
                        <div className="btn__container">
                            <NavLink to="/contact"><button className="header__btn schedule">Schedule Today</button></NavLink>
                            <NavLink to="/services"><button className="header__btn service">See What We Offer</button></NavLink>
                        </div>
                    </div>
                </div>
            </header>
            
        {/* Middle Section of Home Page */}
            <section className="mid__home">
              <div className="mid__left">
                <h2>
                    Ride the wave of clean with Tsunami Washing Services.
                </h2>
                <p>
                    At Tsunami Washing Services, we offer pressure washing and soft washing services. 
                    Utilizing the lastest washing technologies, we can guarantee a clean that will have you feeling refreshed and satisfied.
                <br/>
                Located on the North Shore of Oahu, we offer all of our services to every ohana and business island wide.
                From Mauka to Makai, Tsunami Washing Services has got you covered.
                   
                </p>
              </div> 
              
              <img className="mid__beach" src={sideBySide} alt="beach"/>
              
            </section>
            <section className="bottom__home" id="gallery">
                <h3 className="resize__header">From family homes to businesses, schedule today to give your property the clean it deserves.</h3>
                <Gallery />
                <div className="bottom__left">
                    <h3>From family homes to businesses, schedule today to give your property the clean it deserves.</h3>
                    <NavLink to="/contact"><button className="bottom__btn">Schedule Today</button></NavLink>
                </div>
            </section>
            <section className="review__section">
                <h3>We thrive to give each customer the best experience and results that they can possibly ask for. Quality and compassion is never lacked with Tsunami Washing Services.</h3>
                <p>See what our satisfied customers have to say!</p>
                <ReviewWidget />
            </section>
            <section className="end__section">
                <h3 className="end__header">Tsunami Washing Services is the one stop shop for all your exterior washing needs.</h3>
                <div className="end__container"> 
                    <img className="left__img" src={endImg} alt="side by side comparison of side walk" />
                    <div className="right__end">
                        <div className="icon__header">
                            <Icon component={Shower} sx={{fontSize: 60, borderBottom: '4px solid #00a7fb'}}/>
                            <Icon component={WaterDamage} sx={{fontSize: 60, borderBottom: '4px solid #00a7fb'}}/>
                            <Icon component={Roofing} sx={{fontSize: 60, borderBottom: '4px solid #00a7fb'}}/>
                        </div>
                        <h4>We're just one call or email away! So pick up your phone now and let us give your home or business the <u>Tsunami Washing Service</u> shine!</h4>
                        <div className="contact__box">
                            <div className="phone">
                                <PhoneIcon />
                                <a className='phone__link' href='tel:+18084577481' alt="Phone link">+1(808) 457-7481</a>
                            </div>
                            <Link to='/contact' className="link__btn"><button className="email__btn">Email Us</button></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;
import React, {  useRef, useEffect } from 'react';
import emailjs from "emailjs-com";
import { Card, CardContent, Grid, TextField, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../../images/tsunami-nav.png';
import "./contact.css";

const Contact = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1h3tri4', 'template_9qdneio', form.current, 'esxuJGf416TF3QYgw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();

    }
    
    return (
        <>
            <div className="contact__container">
                <div className="contact__left fade-in">
                    <img src={logo} alt='tsunami washing services logo'/>
                    <h3>Contact our team today.</h3>
                    <p className='left__p'>Leave us an email using the form, or give us a call to schedule the clean your home or business has been needing!</p>
                    <div className='socials'>
                        <div className='social__item'>
                            <PhoneIcon />
                            <p>+1(808) 457-7481</p>
                        </div>
                        <div className='social__item'>
                            <InstagramIcon />
                            <p>@tsunamiwashingservices</p>
                        </div>
                        <div className='social__item'>
                            <FacebookIcon />
                            <p>Tsunami Washing Services LLC.</p>
                        </div>
                    </div>
                </div>

                <div className="contact__right fade-in">
                    <Card style={{maxWidth: "90%", margin: "0 auto", padding: "10px 10px"}}>
                        <CardContent >
                        <form ref={form} onSubmit={sendEmail}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField label="Name" placeholder='Enter first and last name' name='name' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Address" placeholder='Enter your address' name='address' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Service" placeholder='Enter which service' name='service' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField type="email" label="Email" placeholder='Enter email' name='email' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField type="number" label="Phone" placeholder='Enter phone number' name='phone' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Message" multiline rows={4} placeholder='Type message here' name='message' variant='outlined' fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button className='submit__button' type='submit' variant='contained' fullWidth style={{backgroundColor: "#125488"}}>Submit</Button>
                                </Grid>
                            </Grid> 
                    </form> 
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Contact;
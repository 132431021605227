import React, { useEffect } from "react";
import headBackground from "../../images/beach.jpg";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import "./about.css";

const About = () => {

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <div className="about"  style={{backgroundImage: `url(${headBackground})`}}>
                <div className="about__container fade-in">
                    <header className="about__header" >
                        <h1>Who We Are</h1>
                        <p>
                            Tsunami Washing Services is a locally owned and operated business. We thrive to offer and give the best washing services on island, 
                            as well as the best customer experience to ensure that every business & ohana is left smiling and satisfied with the quality of our work.  
                        </p>
                    </header>
                    <Card sx={{ minWidth: 200, margin: 2}} style={{backgroundColor: 'transparent', textAlign:'center', border: '1px solid #2586B4', maxWidth: '88%', margin: '0 auto'}}>
                        <CardContent >
                            <Typography variant="h4" style={{fontFamily: 'Outfit, sans-serif', color: 'white'}}>
                                How We Started
                            </Typography>
                            <Typography variant="h6" style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontWeight: '400'}} gutterBottom>
                                -Our Story-
                            </Typography>
                            <Typography style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontSize: '1.3rem', fontWeight: '200'}} variant="body1">
                            Established on the North Shore of Oahu, Tsunami Washing Services originated from a passion for pressure washing as a hobby and side venture. Initially, the company focused on servicing the properties of friends and family, gradually expanding its clientele. Recognizing the potential for growth, owner and founder Koa Matsumoto transitioned to pressure washing as a full-time endeavor, laying the foundation for the company as it is known today. Committed to excellence, Koa continuously invests in advanced equipment, researches innovative techniques, and acquires knowledge to ensure the delivery of exceptional services.
                                <br />
                            </Typography> 
                        </CardContent>
                    </Card>
                    <section className="about__cards">
                        <Card sx={{ minWidth: 200, margin: 1 }} style={{backgroundColor: 'transparent', border:'1px solid #2586B4', textAlign:'center'}}>
                            <CardContent >
                                <Typography variant="h4" style={{fontFamily: 'Outfit, sans-serif', color: 'white'}}>
                                    What We Do
                                </Typography>
                                <Typography variant="h6" style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontWeight: '400'}} gutterBottom>
                                    -Our Mission-
                                </Typography>
                                <Typography style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontSize: '1.3rem', fontWeight: '200'}} variant="body1">
                                Our mission is to provide the highest quality of pressure washing and soft washing services to residential and commercial properties with the most consistent, efficient, and professional approach. We believe in quality and attention to detail no matter how big or small the job is. 
                                    <br />
                                </Typography> 
                            </CardContent>
                        </Card>
                        
                        <Card sx={{ minWidth: 200, margin: 1}} style={{backgroundColor: 'transparent', border:'1px solid #2586B4', textAlign:'center'}}>
                            <CardContent >
                                <Typography variant="h4" style={{fontFamily: 'Outfit, sans-serif', color: 'white'}}>
                                    What We Want
                                </Typography>
                                <Typography variant="h6" style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontWeight: '400'}} gutterBottom>
                                    -Our Vision-
                                </Typography>
                                <Typography style={{fontFamily: 'Outfit, sans-serif', color: 'white', fontSize: '1.3rem', fontWeight: '200'}} variant="body1">
                                Our aim is to cultivate enduring relationships with our clientele by offering dependable, high-quality services at competitive rates. We are committed to fostering growth within communities across the island by maintaining the beauty and cleanliness of properties.
                                    <br />
                                </Typography> 
                            </CardContent>
                        </Card>
                    </section>
                </div>
            </div>
        </>
    )
}

export default About;
import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Turn as Hamburger } from 'hamburger-react';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from "../../images/tsunami-nav.png";

import './navbar.css';

const NavBar = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)

    }, []);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log('triggered');
    };

    const handleItemClick = () => {
        setIsMenuOpen(false);
    }

    return(
        <>
            <nav className={isScrolled ? "navbar scrolled" : "navbar"}>
                <NavLink to="/"><img src={logo} alt="Logo"  onClick={handleItemClick}/></NavLink>
                <div className='hamburger' onClick={toggleMenu}>
                    <Hamburger direction='right' toggled={isMenuOpen} toggle={setIsMenuOpen}/>
                </div>
                <ul className={`links ${isMenuOpen ? 'is-open' : ''}`}>
                    <li>
                        <NavLink to="/services" className='nav__item' onClick={handleItemClick}>Services</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" className='nav__item' onClick={handleItemClick}>About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/gallery" className='nav__item' onClick={handleItemClick}>Gallery</NavLink>
                    </li>
                    <li>
                        <a className='icon__link' href='tel:+18084577481' alt="Phone link"><PhoneIcon /></a>
                    </li>
                    <li>
                        <a className='icon__link' href="https://www.instagram.com/tsunamiwashingservices/" target="_blank" rel="noopener noreferrer" alt="Tsunami Washing Service Instagram"><InstagramIcon /></a>
                    </li>
                    <li>
                        <a className='icon__link facebook' href="https://www.facebook.com/Tsunamiwashingservicesllc" target="_blank" rel="noopener noreferrer" alt="Tsunami Washing Service Facebook"><FacebookIcon /></a>
                    </li>
                    <NavLink to="/contact" className="contact__btn"><button className="nav__button" onClick={handleItemClick}>Contact</button></NavLink>
                </ul>            
            </nav>
        </>    
    )
}

export default NavBar;

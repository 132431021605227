import React, { useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ShowerIcon from '@mui/icons-material/Shower';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import RoofingIcon from '@mui/icons-material/Roofing';

import "./services.css";
import { Icon } from "@mui/material";

const Services = () => {

    useEffect(() => {
        window.scroll(0,0)
    }, [])
      
    return (
        <>
            <div className="service__page">
                <div className="service__header fade-in">
                    <h2>
                        Select from our options of services that suits your needs best. 
                    </h2>
                    <p>
                    From residential, commercial, to even a specific need, we've got you covered!
                    </p>
                </div>
                <div className="services__container fade-in">
                    <section className="card__section">
                        <Card sx={{ maxWidth: 550, margin: 1 }}>
                            <CardContent sx={{ maxWidth: 550}} >
                                <Icon component={ShowerIcon} sx={{fontSize: 50, color: '#00a7fb'}} />
                                <Typography variant="h5" style={{fontFamily: 'inherit'}} gutterBottom>
                                    Pressure Washing
                                </Typography>
                                <Typography style={{fontFamily: 'inherit'}} variant="body1">
                                    Pressure washing works great for hard surfaces such as flagstone, concrete and brick. It is a common thing to find growth of moss, algae and other tiny organisms on surfaces that can cause a slip hazard, like your driveway or sidewalk. Pressure washing will be able to remove such organisms! We also offer a post treatment coat that will kill the remaining bacteria and keep the surfaces cleaner for a longer time period, giving your property that extra shine.
                                    <br />
                                </Typography> 
                            </CardContent>
                        </Card>
                        <Card sx={{ maxWidth: 550, margin: 1 }}>
                            <CardContent sx={{ maxWidth: 550}}>
                                <Icon component={WaterDamageIcon} sx={{fontSize: 50, color: '#00a7fb'}} />
                                <Typography variant="h5" style={{fontFamily: 'inherit'}} gutterBottom>
                                    House Washing
                                </Typography>
                                <Typography variant="body1" style={{fontFamily: 'inherit'}}>
                                    House cleaning includes a soft wash. Soft washing will safely remove organic growth stains, mildew, moss, algae, salt and dirt that builds up on your home's exterior walls over time. Letting our soft wash solution do its work, along with low pressure washing techniques, will eliminate the risk factor of potentially damaging the exterior paint and trim of your home. Soft washing is the safest way to clean, protect and brighten up the look of your home today.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ maxWidth: 550, margin: 1 }}>
                            <CardContent sx={{ maxWidth: 550}}>
                                <Icon component={RoofingIcon} sx={{fontSize: 50, color: '#00a7fb'}} />
                                <Typography variant="h5" style={{fontFamily: 'inherit'}} gutterBottom>
                                    Roof Cleaning
                                </Typography>
                                <Typography variant="body1" style={{fontFamily: 'inherit'}}>
                                    Cleaning your roof can extend the duration of functionality and preserve the material! Moss, algae and other types of build up often form on roofs and reduce its life expectancy. The presence of these build ups can affect how much sunlight is absorbed and affect the amount of heat your home absorbs as well. Using soft wash techniques, we can safely remove organic growth, salt residue, dirt and grime to prevent an early roof replacement.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Services;

import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';

const ReviewWidget = () => {
  const widgetId = '30eef346-fe3d-4cdd-bb60-3dc14ee1a705';

  return <ElfsightWidget widgetId={widgetId} style={{fontFamily: "Outfit, sans-serif", color: 'white'}} />;
};

export default ReviewWidget;




import React from 'react';
import { Icon } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import './footer.css';



const Footer =  () => {
    return(
        <footer>
            <div className='footer__container'>
                <address className='contact__card'>
                    Koa Matsumoto | Founder & Owner <br/>
                    North Shore, Oahu <br/>
                    +1(808) 457-7481 <br/>
                    tsunamiwashingservices@gmail.com <br/>
                </address>
                <div className='social__icons'>
                    <a href="https://www.instagram.com/tsunamiwashingservices/" target="_blank" rel="noopener noreferrer" alt="Tsunami Washing Service Instagram">
                        <Icon component={InstagramIcon} sx={{fontSize: '35px', color: 'white', margin: '0 5px'}} className='footer__icon' />
                    </a>
                    <a href="https://www.facebook.com/Tsunamiwashingservicesllc" target="_blank" rel="noopener noreferrer" alt="Tsunami Washing Service Facebook">
                        <Icon component={FacebookIcon} sx={{fontSize: '35px', color: 'white', margin: '0 5px'}} className='footer__icon' />
                    </a>
                    <a href="https://www.google.com/maps/place/Tsunami+Washing+Services+LLC/@21.4837543,-157.9652284,11z/data=!4m6!3m5!1s0x2885995fbe89431b:0x3da40bb2059f3fb1!8m2!3d21.4837543!4d-157.9652284!16s%2Fg%2F11srr_70ft?entry=ttu" rel="noopener noreferrer" alt="Tsunami Washing Service Google">
                        <Icon component={GoogleIcon} sx={{fontSize: '35px', color: 'white', margin: '0 5px'}} className='footer__icon' />
                    </a>
                    
                </div>
            </div>
            <p>©2023 Tsunami Washing Services LLC. All Rights Reserved</p>
        </footer>
    )
}

export default Footer;
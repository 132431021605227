import React from 'react';
import NavBar from './components/NavBar/NavBar';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';

import './App.css';
import Footer from './components/Footer/Footer';

const GalleryPage = React.lazy(() => import('./pages/GalleryPage/GalleryPage'));

function App() {
  
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route 
          path='/gallery' 
          element={ 
            <React.Suspense fallback={<h1 style={{width: "100%",  color: "lightgray",fontSize: "4rem", paddingTop: "6rem",textAlign: "center", height: "100vh"}}>Loading...</h1>}>
              <GalleryPage />
            </React.Suspense> 
          } />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;

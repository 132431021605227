import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import img4 from "../../assets/img4.png";
import img5 from "../../assets/img5.png";
import img6 from "../../assets/img6.png";
import img7 from "../../assets/img7.png";
import img8 from "../../assets/img8.png";
import img9 from "../../assets/img9.png";
import img10 from "../../assets/img10.png"; 

import "./carousel.css";



class Gallery extends React.Component {
    render() {
        return(
            <Carousel className="main-slide" autoPlay interval="5000" transitionTime="1000" infiniteLoop>
                <div className="image">
                    <img  src={img1} alt="comparison 1"/>
                </div>
                <div className="image">
                    <img src={img2} alt="comparison 2"/>
                </div>
                <div className="image">
                    <img src={img3} alt="comparison 3"/>
                </div>
                <div className="image">
                    <img src={img4} alt="comparison 4"/>
                </div>
                <div className="image">
                    <img src={img5} alt="comparison 5"/>
                </div>
                <div className="image">
                    <img src={img6} alt="comparison 6"/>
                </div>
                <div className="image">
                    <img src={img7} alt="comparison 7"/>
                </div>
                <div className="image">
                    <img src={img8} alt="comparison 8"/>
                </div>
                <div className="image">
                    <img src={img9} alt="comparison 9"/>
                </div>
                <div className="image">
                    <img src={img10} alt="comparison 10"/>
                </div>
            </Carousel>
        );
    }
}

export default Gallery;